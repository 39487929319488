.hide {
  display: none;
}

.call:hover + .hide {
  display: flex;
  color: red;
  z-index: 40;
  position: fixed;
  right: 10px;
  top: 50%;
}
