@import "tailwindcss/base";

@import "tailwindcss/components";
@import "https://demos.creative-tim.com/notus-js/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
@import "tailwindcss/utilities";

:root {
  --pc: #00b59f;
  --fc: #212877;
  --tc: #ff6a00;
  --sc: #b832dc;
  scroll-behavior: smooth;
  scrollbar-width: none;
}
@font-face {
  font-family: Bradly;
  src: url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mukta:wght@200;300;400;500;600;700;800&display=swap");
}
body {
  margin: 0;
  font-family: Bradly;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-size: 72.5%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
