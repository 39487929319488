.layout {
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
@keyframes slide_down {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
.preload {
  height: 100vh;
  width: 100vw;
  position: fixed;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: url("../assets//spinner.gif") no-repeat;
  background-color: white;
  background-position: center center;
  z-index: 9999999999;
}
